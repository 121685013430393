import axios from '@/api/request.js'

// 开发环境
// const baseUrl = 'http://192.168.0.222:8186/saas-api'

// 生产环境
const baseUrl = 'https://business.attorneyathena.com/saas-api'

// 登录
const loginIn = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/loginByAccount', data)
}

// 根据商会id获取商会信息
const getChamberById = data => {
  return axios.post(baseUrl + '/athena-consumer/tenantAthenaChamberDO/getChamberById', data)
}

// 根据tenantCode查询租户信息
const getChamberLanguage = data => {
  return axios.post(baseUrl + '/athena-consumer/controlCenterChamberDO/getChamberLanguage', data)
}

// 获取会员缴费详情
const getMembershipFeeDetail = data => {
  return axios.post(baseUrl + '/athena-consumer/userCenterDO/getMembershipFeeDetail', data)
}

// 获取验证码（4位数）
const getVerifyCode4 = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/getSecurityCode', data)
}

// 获取验证码（6位数）
const getVerifyCode6 = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/sendVerifyCode', data)
}

// 注册
const register = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/partyRegistration', data)
}

// 修改密码
const updatePassword = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/modifyPassword', data)
}

// 用户登录、用户注册，都需要请求该接口
const addList = data => {
  return axios.post2(baseUrl + '/athena-consumer/memberRelevance/addList', data)
}

// 通过 email 和 tenantCode 获取 userId 和 chamberId
const getUserIdAndChamberId = data => {
  return axios.post(baseUrl + '/athena-consumer/LinkIndexDO/getUserIdAndChamberId', data)
}

export {
  loginIn,
  getChamberById,
  getChamberLanguage,
  getMembershipFeeDetail,
  getVerifyCode4,
  getVerifyCode6,
  register,
  updatePassword,
  addList,
  getUserIdAndChamberId
}
